.root {
  display: flex;
  gap: 30px;
  flex-direction: column;
}

.title {
  font-size: 36px;
  font-weight: 500;
  margin: 0;
}
