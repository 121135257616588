.root {
  display: grid;
  grid-template-columns: 600px repeat(2, 1fr) 100px;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
}

.permissions {
  text-align: center;
}

.type {
  text-align: right;
}

.description span {
  color: var(--gray-400);
}

.separator {
  width: 100%;
  border: none;
  background: var(--gray-100);
  height: 1px;
  margin: 0;
}

@media screen and (max-width: 1130px) {
  .root {
    grid-template-columns: 400px repeat(2, 1fr) 100px;
  }
}

@media screen and (max-width: 1000px) {
  .root {
    grid-template-columns: 300px repeat(2, 1fr) 100px;
  }
}

@media screen and (max-width: 830px) {
  .root {
    grid-template-columns: 250px repeat(2, 1fr) 100px;
  }
}
