.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--gray-900);
  z-index: 1;
  opacity: 0.5;
}

.box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--white);
  z-index: 2;
  width: calc(100% - 40px);
  height: auto;
  max-width: 650px;
  border-radius: 16px;
  padding: 6px 16px 16px;
}

.topPart {
  display: grid;
  grid-template-columns: 1fr 48px;
  grid-column-gap: 10px;
  align-items: center;
}

.closeBtn {
  background: none;
  border: none;
  padding: 13.81px 15.5px;
  color: var(--gray-800);
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}
