.root {
  border-radius: var(--base-border-radius);
  border: 1px solid var(--success-400);
  color: var(--success-400);
  background-color: var(--success-100);
  font-size: 14px;
  padding: 6px 10px;
  display: inline-block;
}

.root.share {
  color: var(--blue-400);
  border-color: var(--blue-400);
  background-color: var(--blue-50);
}

.root.release {
  color: var(--pink-400);
  border-color: var(--pink-400);
  background-color: var(--pink-50);
}
