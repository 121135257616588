.root {
  background-color: var(--white);
  padding: 16px;
  border-radius: 8px;
}

.root .inner {
  display: grid;
  grid-template-columns: 46px 1fr 24px;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: start;
  gap: 16px;
}

.icon {
  height: 46px;
  display: grid;
  background-color: var(--success-400);
  border-radius: 4px;
  justify-items: center;
  align-items: center;
}

.icon svg path {
  fill: var(--primary-800);
}

.size {
  font-size: 14px;
}

.remove svg path {
  fill: var(--gray-500);
}

.remove {
  cursor: pointer;
}

.loadingBar {
  width: 100%;
  margin-top: 2px;
  display: grid;
  grid-template-columns: 1fr 36px;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  align-items: center;
  gap: 17px;
}

.fullBar {
  width: 100%;
  min-height: 6px;
  border-radius: 16px;
  background-color: var(--gray-200);
  position: relative;
}

.state {
  height: 6px;
  border-radius: 16px;
  background-color: var(--secondary-400);
}

.percentage {
  color: var(--gray-600);
  font-size: 14px;
  text-align: right;
}
