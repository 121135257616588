.layoutContainer {
  display: grid;
  height: 100vh;
  display: grid;
  grid-template-columns: 480px 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  transition: 0.2s all ease-in-out;
}

@media screen and (max-width: 1000px) {
  .layoutContainer {
    grid-template-columns: 350px 1fr;
  }
}

@media screen and (max-width: 830px) {
  .layoutContainer {
    grid-template-columns: 300px 1fr;
  }
}

.sidebar {
  height: 100%;
  background-image: url('../../assets/loginBackground.jpg');
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  position: relative;
  display: grid;
  align-items: center;
  justify-items: center;
}

.inner {
  text-align: center;
  color: var(--white);
}

.logo {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-items: center;
}

.inner p {
  font-size: 18px;
  margin: 0;
}

.content {
  max-width: 400px;
  margin: auto auto;
  width: calc(100% - 40px);
}
