.canvas {
  width: 100%;
  border-radius: 8px;
}

.thumbnails {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

.frame {
  border-radius: 5px;
  width: 100%;
  height: auto;
}

.root {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.twoButtons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.loadingGhost .canvas {
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  color: transparent;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
