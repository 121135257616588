.rootLeft {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
