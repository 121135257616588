.signInForm p {
  font-weight: 400;
  font-size: 16px;
  color: var(--light-gray);
}

.formTitle {
  font-weight: 500;
  font-size: 30px;
  color: var(--dark-gray);

  text-align: center;
  margin-bottom: 1.5em;
}

.formTitle {
  text-align: center;
  margin: 0;
  font-weight: 400;
  font-size: 36px;
}

.formTitle > span {
  font-weight: 700;
}

.signInForm {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.signInForm p {
  margin: 0;
  font-weight: 300;
}

.signInForm > p > span > a {
  color: var(--primary-color);
  text-underline-offset: 2px;
}

p.erorr {
  background-color: var(--error-400);
  padding: 5px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  color: var(--gray-800);
  text-align: center;
}
