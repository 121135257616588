.root {
  width: 100%;
  background-color: var(--white);
  border-radius: 16px;
  padding: 16px 16px 84px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: 0.2s all ease-in-out;
}

.root:not(.locked) {
  box-shadow: 4px 40px 40px -40px rgba(88, 91, 227, 0.3);
}

.root:hover {
  box-shadow: 4px 40px 40px -30px rgba(88, 91, 227, 0.5);
}

.newTag {
  position: absolute;
  top: 16px;
  right: 16px;
}

.name {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.name h2 {
  font-size: 24px;
  margin: 0;
}

.image {
  background-color: var(--warning-200);
  width: 70px;
  height: 70px;
  text-align: center;
  border-radius: var(--base-border-radius);
  display: grid;
  align-items: center;
  justify-items: center;
}

.locked .image {
  background-color: var(--error-400);
}

.image svg path {
  fill: var(--white);
}

.infoRows {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.infoRow {
  display: flex;
  gap: 5px;
}

.infoRow .label {
  color: var(--gray-400);
  width: 80px;
}

.separator {
  width: 100%;
  border: none;
  background: var(--gray-100);
  height: 1px;
  margin: 0;
}

.separator.absolute {
  position: absolute;
  bottom: 70px;
  left: 16px;
  right: 16px;
  width: calc(100% - 16px - 16px);
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.buttons.absolute {
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
  width: calc(100% - 16px - 16px);
}

.locked {
  pointer-events: none;
  position: relative;
  overflow: hidden;
}

.locked:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--gray-200);
  opacity: 0.5;
}

.loadingGhost {
  pointer-events: none;
}

.loadingGhost .name h2,
.loadingGhost .infoRow .label,
.loadingGhost .infoRow .value,
.loadingGhost .image {
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  color: transparent;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
