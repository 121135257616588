.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
}

.input {
  border: 1px solid var(--gray-100);
  padding: 12px 16px;
  border-radius: var(--base-border-radius);
  font-weight: 400;
  width: 100%;
  transition: 0.2s all ease-in-out;
}

.input:focus {
  background-color: var(--gray-100);
  outline: none;
}

.label {
  font-weight: 500;
  font-size: 14px;
  color: var(--gray-700);
}

.label sup {
  color: var(--success-400);
}

.error {
  background-color: var(--error-400);
  padding: 2px 5px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
}
