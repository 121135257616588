.root {
  background-color: var(--secondary-400);
  border-radius: var(--base-border-radius);
  border: 1px solid var(--secondary-400);
  padding: 9px 20px;
  color: var(--white);
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.root.large {
  padding: 15px 20px;
}

.root.navy:hover {
  background-color: var(--secondary-400);
  border-color: var(--secondary-400);
}

.root:not(.navy):hover,
.root.withIcon.naked:hover {
  background-color: var(--primary-800);
  border-color: var(--primary-800);
  color: white;
}

.root.fullWidth {
  width: 100%;
}

.naked {
  background-color: var(--white);
  border-color: var(--secondary-50);
  color: var(--secondary-400);
}

.navy {
  background-color: var(--primary-800);
  border-color: var(--primary-800);
}

.root:disabled {
  background-color: var(--white);
  border-color: var(--secondary-50);
  color: var(--gray-400);
  pointer-events: none;
}

.root.withIcon {
  display: flex;
  align-items: center;
  gap: 16px;
}

.root.withIcon.naked {
  border-color: var(--gray-200);
  color: var(--gray-500);
}

.loadingGhost,
.loadingGhost:disabled {
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1s shine linear infinite;
  color: transparent;
  border-color: transparent;
  pointer-events: none;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
